import { googleTagManager } from '../../../server/scripts'

type ScriptTag = {|
  async?: boolean,
  innerHTML?: string,
  src?: string,
  strategy?: string,
  type?: string
|}

export const baseScripts = (props): ScriptTag[] => {
  const scripts = []

  if (props.hasGtmConsent) {
    scripts.push({
      type: 'text/javascript',
      innerHTML: googleTagManager,
    })
  }
  return scripts
}

// @flow

import React, { useState, useEffect, useRef } from 'react'
import take from 'lodash/take'
import { useDispatch, useSelector } from 'react-redux'

import UploadDocumentButton from './UploadDocumentButton'
import { MAX_REFERENCES } from '../../../constants/base'
import UploadedDocuments from './UploadedDocuments'
import {
  pollingReferenceRetriesExhausted,
  pollReferenceConversion,
  removeReference,
  uploadReference,
} from '../../../actions/actionCreators'
import { catchFileError } from './catchFileErrors'
import { generateTemporaryIdForReferenceFile } from './index'
import type { ApplicationState } from '../../../types/applicationState'
import type { CVShape, ReferencesShape } from '../../../types/application'
import { injectIntl, type IntlShape } from 'react-intl'
import { HBox } from 'talent-ui'
import FileDropZone from './FileDropZone.tsx'
import { useWindowDrag } from '../hooks/useWindowDrag.ts'
import { fireSnowplowStructuredEvent } from '../../../tracking/external/trackingClient'

type ComponentProps = {|
  beforeUpload?: (files: FileList) => void,
  disableWaitForDocumentConversion?: boolean,
  onReferenceRemove?: () => void,
  onUploadSuccess?: () => void,
  showUpload: boolean,
|}

type UploadReferencesSectionProps = {|
  ...ComponentProps,
  cv: CVShape,
  intl: IntlShape,
  references: ReferencesShape
|}

const _UploadReferencesSection = (ownProps: ComponentProps) => {
  const props: UploadReferencesSectionProps = useSelector((state: ApplicationState) => ({
    ...ownProps,
    cv: state.documents.applicationCv,
    references: state.documents.applicationReferences,
    disableWaitForDocumentConversion: state.documents.disableWaitForDocumentConversion || ownProps.disableWaitForDocumentConversion,
  }))

  const [isDragging, setIsDragging] = useState(false)

  const dispatch = useDispatch()

  const documentConversionStatusMap = useRef({})
  const timeoutIdsMap = useRef(new Map())
  const pollingError = useRef([])
  const areRetriesExhausted = useRef(false)

  useEffect(() => {
    if (!props.disableWaitForDocumentConversion) {
      const notConvertedFiles = props.references.files.filter((file) => !file.is_converted)
      pollingError.current = props.references.files.some((file) => file.errors.length > 0)
      props.references.files.forEach((file) => {
        if (file.file?.id) {
          documentConversionStatusMap.current[file.file?.id] = file.is_converted
        }
      })

      notConvertedFiles.forEach((file) => {
        if (file.file?.id && !file.is_converted && !file.converting && !areRetriesExhausted.current) {
          pollDocumentConversion(file.file.id).then()
        }
      })
    }
  }, [props.references])

  const generateFileData = (file: File) => ({
    temporaryId: generateTemporaryIdForReferenceFile(file), // used as local key
    name: file.name,
  })

  const uploadDocuments = async (files, references) => {
    // limit the amount of files to the maximum when uploading
    const numberReferencesAlreadyUploaded = references.files.length
    const limitedFiles = take(
      Array.from(files),
      MAX_REFERENCES - numberReferencesAlreadyUploaded
    )

    props.beforeUpload?.(files)

    try {
      await Promise.all(
        limitedFiles.map(async (file) => {
          dispatch(uploadReference(file, generateFileData(file)))
          areRetriesExhausted.current = false
        })
      )

      props.onUploadSuccess?.()
    } catch (error) {
      catchFileError(error)
    }
  }

  const removeDocument = (file) => () => {
    // NOTE: used by reducers/documents/mergeReferenceFile
    // $FlowFixMe -- [KDB] I think this should be `file.file?.temporaryId`
    const meta = { temporaryId: file.temporaryId }
    clearTimeout(timeoutIdsMap.current.get(file.file?.id))
    timeoutIdsMap.current.delete(file.file?.id)
    dispatch(removeReference(file, meta))
    props.onReferenceRemove && props.onReferenceRemove()
  }

  const timeout = (ms, fileId) => {
    return new Promise((resolve) => {
      const timeoutId = setTimeout(resolve, ms)
      timeoutIdsMap.current.set(fileId, timeoutId)
    })
  }

  const pollDocumentConversion = async (fileId) => {
    const MAX_POLLING_RETRIES = 25
    let retryCount = 0

    while (retryCount < MAX_POLLING_RETRIES) {
      if (documentConversionStatusMap.current[fileId]) {
        break
      }
      try {
        await dispatch(pollReferenceConversion(fileId))
      } catch (error) {
        catchFileError(error)

        areRetriesExhausted.current = true
        return
      }
      await timeout(5000, fileId)
      retryCount += 1
    }

    if (retryCount >= MAX_POLLING_RETRIES && !documentConversionStatusMap.current[fileId]) {
      areRetriesExhausted.current = true
      dispatch(pollingReferenceRetriesExhausted({ errors: [{ key: 'could_not_process_file' }] }))
    }
    if (pollingError.current && !documentConversionStatusMap.current[fileId]) {
      dispatch(pollingReferenceRetriesExhausted({ errors: [{ key: 'could_not_process_file' }] }))
    }

    timeoutIdsMap.current.delete(fileId)
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = (event) => {
    event.preventDefault()

    const droppedFiles = Array.from(event.dataTransfer.files)

    const limitedFilesNames = droppedFiles?.map(file => file?.name).join(', ')

    fireSnowplowStructuredEvent({
      category: 'tp_page_action',
      action: 'references_drag_and_drop',
      label: limitedFilesNames,
    })

    uploadDocuments(droppedFiles, props.references)
  }

  useWindowDrag(
    {
      dragEnterCallback: () => setIsDragging(true),
      dragLeaveWindowCallback: () => setIsDragging(false),
      dragDropWindowCallback: () => setIsDragging(false),
    }
  )

  return (
    <HBox
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >

      {
        isDragging && (
          <FileDropZone
            dataTestId='file-dropzone-references'
            lokaliseKey='job_application_other_documents_upload_drag_and_drop'
          />)
      }

      {!!props.references.files.length && !isDragging && (
        <HBox
          mb={6}
          md={{ mb: 8 }}
        >
          <UploadedDocuments
            removeCv={removeDocument}
            documents={props.references.files}
            errors={props.references?.errors}
          />
        </HBox>
      )}

      {props.showUpload && props.references.files.length < MAX_REFERENCES && !isDragging && (
        <HBox>
          <UploadDocumentButton
            id='reference'
            uploadCv={(files) => uploadDocuments(files, props.references)}
            buttonText='job_application_conversation_flow_document_upload_add_more_documents'
          />
        </HBox>
      )}
    </HBox>
  )
}

const UploadReferencesSection = injectIntl(_UploadReferencesSection)

export default UploadReferencesSection

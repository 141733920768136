// File is basically untested in JSP
/* istanbul ignore file */
import find from 'lodash/find'
import get from 'lodash/get'
import includes from 'lodash/includes'
import omit from 'lodash/omit'

import logger from '../../logging'
import * as actions from '../constants/actions'
import {
  DEFAULT_SUBSCRIBE_CONSENT_TYPE,
  DEFAULT_UNSUBSCRIBE_CONSENT_TYPE, MAX_DOCUMENT_NAME_LENGTH, ONE_MB, UPLOAD_FILE_MIME_TYPES, UPLOAD_FILE_MIME_TYPE_EXTENSIONS, UPLOAD_FILE_SIZE_LIMIT,
} from '../constants/base'
import { ERROR_CODES_TO_IGNORE } from '../constants/errorCodes'
import { getFeedbackModalActionType } from '../constants/modal'
import transformToSearchAPIOptions from '../epics/searchEpic/transformToSearchAPIOptions'
import { handleExpectedErrors } from '../functions_/shared/handleExpectedErrors'
import * as marketingParamStore from '../helpers/marketingParamStore'
import { GENERIC_STATUSES } from '../pages/Applications/constants'
import { REJECTED } from '../reducers/promiseUtil'
import {
  trackBrazeCustomEvent,
} from '../tracking/clients/braze'
import { APPLICATION_PENDING_STATUS_TRIGGERED_EVENT, APPLICATION_SUBMISSION_EVENT, CONTACT_INFORMATION_CONFIRMED_EVENT, CRM_CONSENT_GIVEN_EVENT, PROFILE_SHARING_CONSENT_EVENT, USER_CREATED_EVENT } from '../tracking/eventNames'
import { CONVERSATIONAL_APPLICATION_FLOW_SOURCE } from '../tracking/eventSources'
import { fireSnowplowEvent } from '../tracking/external/trackingClient'
import buildJobContextTrackingEventData from '../tracking/misc/buildJobContextTrackingEventData'
import * as API from './api/clients'
import users from './api/clients/users'
import ApiError from './api/errors/ApiError'
import * as userActions from './user'
import { addPublicationData } from './utils/jobPublicationData'
import * as utils from './utils/reduxUtils'
import { showJobAlertModal, hideJobAlertModal } from '../reducers/jobAlertModal.ts'

const log = logger('actions')

const PASS_ARGS_AS_META = true

const extension = (mimeType) => UPLOAD_FILE_MIME_TYPE_EXTENSIONS[mimeType]
const updateCurrentJob = async (dispatch, data, config) => {
  // TODO: job.application got updated, so refetch the entire job into state
  // should be refactored to use a more granular update
  const updatedJob = await API.jobs.get(data.job_id, undefined, config)
  dispatch(setCurrentJob(addPublicationData(updatedJob)))
}

export const setCurrentJob = utils.create(actions.SET_CURRENT_JOB, 'job')

const showLoginApplicationForm = utils.create(
  actions.SHOW_LOGIN_APPLICATION_FORM
)
export const showJobApplicationError = utils.create(
  actions.SHOW_JOB_APPLICATION_ERROR,
  'error'
)
export const hideJobApplicationGenericError = utils.create(
  actions.HIDE_JOB_APPLICATION_ERROR
)
export const showJobApplicationGenericErrorNotified = utils.create(
  actions.SHOW_JOB_APPLICATION_ERROR_NOTIFIED
)
export const updateCurrentUser = utils.createPromise(
  actions.UPDATE_CURRENT_USER,
  (id, data) => (config) => users.update(id, data, config)
)
export const updateIsCRMModalsAllowed = utils.create(
  actions.UPDATE_IS_CRM_MODALS_ALLOWED,
  'isCRMModalsAllowed'
)
export const toggleMobileSearchView = utils.create(actions.TOGGLE_MOBILE_SEARCH_VIEW)
export const toggleMobileFilterSection = utils.create(
  actions.KEYWORD_SEARCH_TOGGLE_MOBILE_FILTERS_SECTION_EXPANDED,
  'name'
)
export const modifyJobSearch = utils.create(actions.JOB_SEARCH_MODIFY)
export const emptyMobileFiltersBucket = utils.create(
  actions.KEYWORD_SEARCH_MOBILE_EMPTY_FILTERS_BUCKET
)
export const showAlreadyAppliedError = utils.create(
  actions.SHOW_ALREADY_APPLIED_TO_JOB_ERROR,
  'job'
)

export const reserveEnteredData = utils.create(
  actions.RESERVE_ENTERED_DATA,
  'email',
  'name'
)

export const removeQueryParam = utils.createPromise(
  actions.REMOVE_QUERY_PARAM,
  (history, paramName) => (_config) => {
    const queryParams = new URLSearchParams(location.search)

    if (queryParams.get(paramName) !== null) {
      queryParams.delete(paramName)
      history.replace({
        search: queryParams.toString(),
      })
    }

    return new Promise((resolve) => {
      resolve()
    })
  }
)

export const setRedirectPath = utils.create(
  actions.SET_REDIRECT_PATH,
  'redirect'
)

const getFileUploadErrors = (file) => {
  const errors = []
  if (file.name.length >= MAX_DOCUMENT_NAME_LENGTH) {
    errors.push({
      key: 'error_file_name_length',
      values: { maximumLength: MAX_DOCUMENT_NAME_LENGTH },
    })
  }

  if (!includes(UPLOAD_FILE_MIME_TYPES, file.type)) {
    errors.push({
      key: 'error_file_type',
      values: {
        acceptedTypes: UPLOAD_FILE_MIME_TYPES.map(extension).join(', '),
      },
    })
  }

  if (!(file.size <= UPLOAD_FILE_SIZE_LIMIT * ONE_MB)) {
    errors.push({
      key: 'error_file_size',
      values: { size: UPLOAD_FILE_SIZE_LIMIT },
    })
  }

  return errors
}

const crmConsentTrackingTypes = {
  job_recommendations: 'email',
  job_recommendations_whatsapp: 'whatsapp',
}

const fireCRMConsentEvent = (options, trackingOptions) => {
  const crmType = crmConsentTrackingTypes[options.consent_type]

  if (crmType && options.consent_value === true) {
    fireSnowplowEvent(CRM_CONSENT_GIVEN_EVENT, {
      crm_type: crmType,
      source: trackingOptions.source,
    }, [{ key: 'job_search_result_context' }, { key: 'job_feed_context' }])
  }
}

const fireProfileSharingConsentEvent = (options, trackingOptions) => {
  fireSnowplowEvent(PROFILE_SHARING_CONSENT_EVENT, {
    source: trackingOptions.source,
    job_id: trackingOptions.job_id,
    job_type: trackingOptions.job_type,
    application_id: trackingOptions.application_id,
    consent_given: options.consent_value,
  })
}

const contactConfirmationTypes = {
  [DEFAULT_SUBSCRIBE_CONSENT_TYPE]: 'phone_number',
  [DEFAULT_UNSUBSCRIBE_CONSENT_TYPE]: 'email',
}

const fireContactConfirmationEvent = (options) => {
  const contactType = contactConfirmationTypes[options.consent_type]
  if (contactType) {
    fireSnowplowEvent(CONTACT_INFORMATION_CONFIRMED_EVENT, { contact_type: contactType })
  }
}

export const routeTransition = utils.create(actions.ON_ROUTE_TRANSITION, 'data')
export const routeDataSync = utils.create(actions.ON_ROUTE_DATA_SYNC, 'data')
export const hideCookieBar = utils.create(actions.HIDE_COOKIE_BAR)

export const showModal = utils.create(
  actions.SHOW_MODAL,
  'key'
)

export const hideModal = utils.create(
  actions.HIDE_MODAL,
  'key'
)

export const showLoginModal = utils.create(
  actions.SHOW_LOGIN_MODAL,
  'options'
)

export const showTermsAndConditionsModal = utils.create(
  actions.SHOW_TERMS_AND_CONDITIONS_MODAL
)

export const hideTermsAndConditionsModal = utils.create(
  actions.HIDE_TERMS_AND_CONDITIONS_MODAL
)

export const showSnackBar = utils.create(
  actions.SHOW_SNACK_BAR,
  'payload'
)

export const hideSnackBar = utils.create(
  actions.HIDE_SNACK_BAR,
  'payload'
)

export const setBookmarkAnimation = utils.create(
  actions.BOOKMARK_ANIMATION,
  'payload'
)

export const userDidNotConsentTermsAndConditions = utils.create(
  actions.USER_DID_NOT_CONSENT_TERMS_AND_CONDITIONS
)

export const updateDeviceTokens = utils.create(actions.UPDATE_DEVICE_TOKEN, 'payload')
export const toggleResendConfirmationModal = utils.create(
  actions.TOGGLE_RESEND_CONFIRMATION_EMAIL_MODAL,
  'options'
)
export const hideLoginModal = utils.create(actions.HIDE_LOGIN_MODAL)
export const showLinkoutModal = utils.create(actions.SHOW_LINKOUT_MODAL)
export const hideLinkoutModal = utils.create(actions.HIDE_LINKOUT_MODAL)
export const setPostRegisterAction = utils.create(
  actions.SET_POST_REGISTER_ACTION,
  'actionName'
)
export const cleanPostRegisterAction = utils.create(actions.CLEAN_POST_REGISTER_ACTION)
export const showSuccessModal = utils.create(actions.SHOW_SUCCESS_MODAL)
export const hideSuccessModal = utils.create(actions.HIDE_SUCCESS_MODAL)
export const handlingLogin = utils.create(actions.HANDLING_LOGIN)

export const confirmEmail = utils.createPromise(
  actions.CONFIRM_EMAIL,
  (confirmationToken) => async (config) => {
    await users.confirmEmail(
      { confirmation_token: confirmationToken },
      config
    )
    fireSnowplowEvent(CONTACT_INFORMATION_CONFIRMED_EVENT, { contact_type: 'email' })
  }
)
export const confirmEmailUnauthorized = utils.createPromise(
  actions.CONFIRM_EMAIL_UNAUTHORIZED,
  (token) => async (config) => {
    await users.confirmEmailUnauthorized({ token }, config)

    fireSnowplowEvent(CONTACT_INFORMATION_CONFIRMED_EVENT, { contact_type: 'email' })
  }
)
export const showCrmOptionsModal = utils.create(
  actions.SHOW_CRM_OPTIONS_MODAL,
  'emailConfirmed'
)
export const hideCrmOptionsModal = utils.create(actions.HIDE_CRM_OPTIONS_MODAL)
export const displayCrmOptionsThankYouModal = utils.create(
  actions.DISPLAY_CRM_THANK_YOU_MODAL
)
export const hideCrmOptionsThankYouModal = utils.create(actions.HIDE_CRM_THANK_YOU_MODAL)
export const createConsent = utils.createPromise(
  actions.CREATE_CONSENT,
  (options, trackingOptions = {}) => async (config) => {
    await users.createConsent(options, config)
    fireCRMConsentEvent(options, trackingOptions)
  }
)

export const createRecommendationConsent = utils.createPromise(
  actions.CREATE_RECOMMENDATION_CONSENT,
  (options, trackingOptions = {}) => async (config) => {
    await users.createConsent(options, config)
    fireCRMConsentEvent(options, trackingOptions)
  }, true)
export const createWhatsAppRecommendationConsent = utils.createPromise(
  actions.CREATE_WHATS_APP_RECOMMENDATION_CONSENT,
  (options, trackingOptions = {}) => async (config) => {
    await users.createConsent(options, config)
    fireCRMConsentEvent(options, trackingOptions)
  }, true)
export const createTermsAndConditionsConsent = utils.createPromise(
  actions.CREATE_TERMS_AND_CONDITIONS_CONSENT,
  (options, trackingOptions = {}) => async (config) => {
    await users.createConsent(options, config)
  }
)
export const createProfileSharingConsent = utils.createPromise(
  actions.CREATE_PROFILE_SHARING_CONSENT,
  (options, trackingOptions = {}) => async (config) => {
    await users.createConsent(options, config)
    fireProfileSharingConsentEvent(options, trackingOptions)
  }, true)
export const resendVerificationEmail = utils.createPromise(
  actions.RESEND_VERIFICATION_EMAIL,
  (data) => (config) => users.resendVerificationEmail(data, config)
)
export const userAlreadyUnsubscribed = utils.create(actions.USER_ALREADY_UNSUBSCRIBED)
export const errorUnsubscribing = utils.create(actions.ERROR_UNSUBSCRIBING)
export const userAlreadySubscribed = utils.create(actions.USER_ALREADY_SUBSCRIBED)
export const errorSubscribing = utils.create(actions.ERROR_SUBSCRIBING)
export const getUserConsentStatusByEmailHash = utils.createPromise(
  actions.GET_USER_CONSENT_STATUS_BY_EMAIL_HASH,
  (hashedEmail) => () =>
    users.getUserConsentStatusByEmailHash(hashedEmail)
)

export const unsubscribeEmail = utils.createPromise(
  actions.UNSUBSCRIBE_EMAILS,
  (hashedEmail, options) => () =>
    users.unsubscribeEmail(hashedEmail, options)
)
export const subscribeEmail = utils.createPromise(
  actions.SUBSCRIBE_EMAILS,
  (hashedEmail, options) => async () => {
    await users.subscribeEmail(hashedEmail, options)
    fireContactConfirmationEvent(options)
  }
)
export const confirmPhoneNumber = utils.createPromise(
  actions.CONFIRM_PHONE_NUMBER,
  (token) => async () => {
    await users.confirmPhoneNumber(token)
    fireSnowplowEvent(CONTACT_INFORMATION_CONFIRMED_EVENT, { contact_type: 'phone_number' })
    trackBrazeCustomEvent && trackBrazeCustomEvent(CONTACT_INFORMATION_CONFIRMED_EVENT, {
      contact_type: 'phone_number',
    })
  }
)

export const getJobAlertSearchQueries = utils.createPromise(
  actions.JOB_ALERT_SEARCH_QUERIES_GET,
  () => (config) =>
    API.jobAlertSearchQueries.get(config)
)

export const createJobAlertSearchQueries = utils.createPromise(
  actions.JOB_ALERT_SEARCH_QUERIES_CREATE,
  (options) => (config) => {
    const payload = omit(transformToSearchAPIOptions(options), ['offset', 'page', 'experiment_variant'])

    return API.jobAlertSearchQueries.create(payload, config)
  }
)

export const deleteJobAlertSearchQueries = utils.createPromise(
  actions.JOB_ALERT_SEARCH_QUERIES_DELETE,
  (uid) => (config) =>
    API.jobAlertSearchQueries.delete(uid, config)
)

export const sendLinkOutJobEmail = utils.createPromise(
  actions.SEND_LINK_OUT_JOB_EMAIL,
  (jobId) => (config) => users.sendLinkOutJobEmail(jobId, config)
)
export const getUserStatusByLoginToken = utils.createPromise(
  actions.GET_USER_STATUS_BY_LOGIN_TOKEN,
  (token) => (config) => users.getUserStatusByLoginToken(token, config)
)
export const showNavMenu = utils.create(actions.SHOW_NAV_MENU)
export const hideNavMenu = utils.create(actions.HIDE_NAV_MENU)
export const setPageLoadRouteData = utils.create(
  actions.SET_PAGE_LOAD_ROUTE_DATA,
  'routeName',
  'routeNames'
)
export const setResponseStatus = utils.create(
  actions.SET_RESPONSE_STATUS,
  'responseStatus'
)
export const setCacheControl = utils.create(
  actions.SET_CACHE_CONTROL,
  'value'
)
export const setTranslations = utils.create(actions.SET_TRANSLATIONS, 'locale', 'messages')
export const setCountry = utils.create(actions.SET_COUNTRY, 'country')

export const requestSignInEmail = utils.createPromise(
  actions.REQUEST_SIGN_IN_EMAIL,
  (email, redirect) => (config) =>
    users.requestSignInEmail(
      {
        email,
        redirect_path: redirect,
      },
      config
    )
)
export const checkBookmarkJobs = utils.createPromise(
  actions.CHECK_BOOKMARK_JOBS,
  (jobIds) => (config) =>
    users.checkBookmarkJobs(jobIds, config)
)

export const saveVisitedJob = utils.createPromise(
  actions.SAVE_VISITED_JOB,
  (jobUID) => (config) => users.saveVisitedJob(jobUID, config),
  true
)

export const bookmarkJob = utils.createPromise(
  actions.BOOKMARK_JOB,
  (jobId) => (config) => {
    return users.bookmarkJob({ job_uid: jobId }, config)
  },
  true
)
export const unBookmarkJob = utils.createPromise(
  actions.UN_BOOKMARK_JOB,
  (jobId) => (config) =>
    users.unBookmarkJob(jobId, config),
  true
)
export const clearBookmarks = utils.create(
  actions.CLEAR_BOOKMARKS
)
export const getBookmarks = utils.createPromise(
  actions.GET_JOB_BOOKMARKS,
  (options) => (config) => users.getMyBookmarks(options, config)
)
export const fetchCurrentJobScreeningQuestions = utils.createPromise(
  actions.FETCH_CURRENT_JOB_SCREENING_QUESTIONS,
  (jobId) => (config) => API.jobs.screeningQuestions(jobId, config)
)
export const fetchSimilarJobs = utils.createPromise(
  actions.FETCH_SIMILAR_JOBS,
  (options) => async (config) => {
    const similarJobs = await API.jobs.similarJobs(options, config)
    const matchingJobs = get(similarJobs, 'matching_jobs') || []

    return matchingJobs
      .map((job) => {
        const withPublicationDate = addPublicationData(job.job)
        job.job = withPublicationDate

        return job
      })
  }
)
export const clearCurrentJob = utils.create(actions.CLEAR_CURRENT_JOB)
export const fetchRecommendedJobs = utils.createPromise(
  actions.FETCH_RECOMMENDED_JOBS,
  (options) => async (config) => {
    const recommendedJobs = await API.jobs.recommendedJobs(options, config)
    const matchingJobs = get(recommendedJobs, 'matching_jobs') || []

    return matchingJobs
  }
)
export const getVisitedJobs = utils.createPromise(
  actions.GET_VISITED_JOBS,
  (jobIds) => (config) => API.jobs.visitedJobs(jobIds, config)
)
export const fetchSeoDetails = utils.createPromise(
  actions.FETCH_SEO_DETAILS,
  (options) => (config) => {
    return API.seo.get(options, config).catch((errors) => ({ errors }))
  },
  true
)
export const getTalentPlatformCounts = utils.createPromise(
  actions.GET_TALENT_PLATFORM_COUNTS,
  (countryCode) => (config) => API.counts.get(countryCode, config)
)
export const toggleSearchFilters = utils.create(
  actions.KEYWORD_SEARCH_SUGGESTIONS_TOGGLE_FILTERS_SIDEBAR
)
export const addToMobileFiltersBucket = utils.create(
  actions.KEYWORD_SEARCH_MOBILE_ADD_TO_FILTERS_BUCKET,
  'name',
  'filters'
)
export const fetchCompanyBySubdomain = (action = actions.FETCH_COMPANY) =>
  utils.createPromise(action, (subdomain) => (config) =>
    API.companies.search({ subdomain }, config)
  )
export const initializeAbTests = utils.create(
  actions.INITIALIZE_AB_TESTS,
  'activeTest'
)
export const initializeAbTestsDefinitions = utils.create(
  actions.INITIALIZE_AB_TESTS_DEFINITIONS,
  'definitions'
)
export const initializeGrowthBookDefinitions = utils.create(
  actions.INITIALIZE_GROWTHBOOK_DEFINITIONS,
  'definitions'
)
export const fetchApplicationDocuments = utils.createPromise(
  actions.FETCH_APPLICATION_DOCUMENTS,
  () => (config) => API.documents.get(config)
)
export const fetchApplications = utils.createPromise(
  actions.FETCH_APPLICATIONS,
  () => (config) => API.jobs.applied(config)
)

const managedCodes = [
  'document_upload_docx_file_encrypted_or_corrupted',
  'document_upload_pdf_file_encrypted_cannot_be_processed',
  'document_upload_pdf_file_corrupted',
  'document_upload_conversion_timeout',
  'document_upload_conversion_failure',
  'error_file_size',
]

const getUploadFailureError = (e) => {
  const errorCode = e?.errors[0]?.code
  const key = managedCodes.includes(errorCode) ? errorCode : 'error_uploading_file'

  return [{ key }]
}

export const uploadCv = utils.createPromise(
  actions.UPLOAD_CV,
  (currentCv, fileToUpload, parserParams) => (config) => {
    const errors = getFileUploadErrors(fileToUpload)

    // if client validation failed, reject immediately with errors, don't bother doing server call
    if (errors.length) return Promise.reject({ errors })

    const formData = new FormData()
    formData.append('file', fileToUpload)
    formData.append('is_cv', 'true')
    formData.append('parse_file', !!parserParams?.shouldParseFile)
    formData.append('is_application', parserParams?.isApplication)

    if (currentCv.file?.id) {
      return API.documents.update(currentCv.file.id, formData, config)
        .catch((e) => {
          const uploadFailureError = getUploadFailureError(e)

          return Promise.reject({ errors: uploadFailureError })
        })
    } else {
      return API.documents.upload(formData, config)
        .catch((e) => {
          const uploadFailureError = getUploadFailureError(e)

          return Promise.reject({ errors: uploadFailureError })
        })
    }
  },
  PASS_ARGS_AS_META
)
export const removeCv = utils.createPromise(actions.REMOVE_CV, (applicationCv) => (config) => {
  if (applicationCv.file?.id) {
    return API.documents.delete(applicationCv.file.id, config)
  } else {
    return Promise.resolve()
  }
})

export const uploadReference = utils.createPromise(
  actions.UPLOAD_REFERENCE,
  (file, _) => (config) => {
    const errors = getFileUploadErrors(file)
    // if client validation failed, reject immediately with errors, don't bother doing server call
    if (errors.length) return Promise.reject({ errors })

    const formData = new FormData()
    formData.append('file', file)
    formData.append('is_cv', 'false')

    return API.documents.upload(formData, config)
      .catch((e) => {
        const uploadFailureError = getUploadFailureError(e)

        return Promise.reject({ errors: uploadFailureError })
      })
  },
  PASS_ARGS_AS_META
)
export const removeReference = utils.createPromise(
  actions.REMOVE_REFERENCE,
  (applicationReference) => (config) => {
    if (applicationReference.file.id) {
      return API.documents.delete(applicationReference.file.id, config)
    } else if (applicationReference.file.temporaryId) {
      return Promise.resolve()
    }
  },
  PASS_ARGS_AS_META
)
export const pollCvConversion = utils.createPromise(
  actions.POLL_CV_CONVERSION,
  (cvId) => (config) => {
    return API.documents.pollDocumentConversion(cvId, config)
      .catch((e) => {
        const uploadFailureError = getUploadFailureError(e)

        return Promise.reject({ errors: uploadFailureError })
      })
  }
)
export const pollReferenceConversion = utils.createPromise(
  actions.POLL_REFERENCE_CONVERSION,
  (documentId) => (config) => {
    return API.documents.pollDocumentConversion(documentId, config)
      .catch((e) => {
        const uploadFailureError = getUploadFailureError(e)

        return Promise.reject({ errors: uploadFailureError })
      })
  },
  PASS_ARGS_AS_META
)

export const pollingReferenceRetriesExhausted = utils.create(
  REJECTED(actions.POLL_REFERENCE_CONVERSION),
  'payload'
)

export const pollingCvRetriesExhausted = utils.create(
  REJECTED(actions.POLL_CV_CONVERSION),
  'payload'
)

export const smartLinkOutJobApply = utils.createPromise(
  actions.SMART_LINK_OUT_JOB_APPLY,
  (applicationData) => async (config, dispatch) => {
    try {
      await API.applications.v1.apply(
        applicationData,
        config
      )
      const updatedJob = await API.jobs.get(applicationData.job_id, undefined, config)
      dispatch(setCurrentJob(addPublicationData({ ...updatedJob, forUser: true })))
    } catch (error) {
      handleExpectedErrors(error, ERROR_CODES_TO_IGNORE)
    }
  }
)

export const markApplicationAsApplied = utils.createPromise(
  actions.MARK_APPLICATION_AS_APPLIED,
  (applicationId) => (config) => {
    return API.applications.v2.updateApplication(
      applicationId,
      {
        status: 'applied',
      },
      config
    )
  }
)

export const toggleApplicationVisibility = utils.createPromise(
  actions.TOGGLE_APPLICATION_VISIBILITY,
  (applicationId, isHidden) => (config) =>
    API.applications.v2.updateJobseekerApplication(
      applicationId,
      { hidden_by_jobseeker: isHidden },
      config
    )
)

export const createApplication = utils.createPromise(
  actions.CREATE_APPLICATION,
  (applicationData) => async (config, dispatch) => {
    await API.applications.v1.apply(
      applicationData,
      config
    )
    await updateCurrentJob(dispatch, applicationData, config)
  }
)

export const updateApplication = utils.createPromise(
  actions.UPDATE_APPLICATION,
  (applicationData, applicationId) => async (config, dispatch) => {
    await API.applications.v2.updateApplication(
      applicationId,
      applicationData,
      config
    )
    await updateCurrentJob(dispatch, applicationData, config)
  }
)

export const jobApply = utils.createPromise(
  actions.JOB_APPLY,
  (applicationData, userData, user, job, applicationId, contextData = {}) => async (
    config,
    dispatch,
    getState,
    [cookies]
  ) => {
    try {
      // pass flag to specify if user is applying for job from company page
      const {
        company: { current: currentCompany } = {},
        session: { device } = {},
        currentJob: { job: currentJob } = {},
        locality: { country: countryCode },
      } = getState()

      const applicationOrigin = currentJob?.career_page_status === 'unpublished'
        ? 'preview'
        : currentCompany
          ? 'career_page'
          : 'search_results_page'

      applicationData.origin = applicationOrigin

      // Include marketing parameters, if they were previously set.
      const mkt = marketingParamStore.load(cookies)()
      if (mkt) {
        applicationData.marketing_parameters = mkt
      }

      let newUser = null
      let application = null
      if (user) {
        if (userData) {
          newUser = await users.update(user.id, userData, config)
        }
        if (applicationId) {
          application = await API.applications.v2.updateApplication(
            applicationId,
            applicationData,
            config
          )
        } else {
          application = await API.applications.v1.apply(
            applicationData,
            config
          )
        }
      } else {
        newUser = await dispatch(userActions.signUp()(userData))

        fireSnowplowEvent(
          USER_CREATED_EVENT,
          {
            source: CONVERSATIONAL_APPLICATION_FLOW_SOURCE,
          },
          [
            {
              key: 'page_context',
              options: {
                link_out_source: contextData.link_out_source,
                pageName: contextData.page_type,
              },
            },
          ]
        )
        application = await API.applications.v1.apply(applicationData, config)

        try {
          await dispatch(createRecommendationConsent({
            consent_type: 'job_recommendations',
            consent_value: true,
          }, {
            source: CONVERSATIONAL_APPLICATION_FLOW_SOURCE,
          }))
        } catch (err) {
          log.error(err)
        }
      }

      // HACKY: job.application got updated, so refetch the entire job into state
      const updatedJob = await API.jobs.get(job.id, countryCode, config)
      dispatch(setCurrentJob(addPublicationData(updatedJob)))

      fireJobApplicationSubmittedSnowplowEvent(
        application,
        newUser || user,
        job,
        device.tracking_token
      )
    } catch (error) {
      if (error instanceof ApiError) {
        if (find(error.errors, { code: 'email_has_already_been_taken' })) {
          dispatch(showLoginApplicationForm())
        } else {
          dispatch(showJobApplicationError(error))
        }
      } else {
        log.error(`Error ${error.message}`, { error })
      }

      handleExpectedErrors(error, ERROR_CODES_TO_IGNORE)
    }
  }
)

export const answerScreeningQuestions = utils.createPromise(
  actions.ANSWER_SCREENING_QUESTIONS,
  (applicationId, answers, jobId) => async (config, dispatch) => {
    try {
      return await API.applications.v2.postScreeningAnswers(
        applicationId,
        { answers },
        config
      )
    } catch (error) {
      handleExpectedErrors(error, ERROR_CODES_TO_IGNORE)
    }
  }
)

export const fireJobApplicationSubmittedSnowplowEvent = (
  application,
  user,
  job,
  trackingToken
) => {
  const contextData = {
    ...buildJobContextTrackingEventData(job),
    user_device_token: trackingToken,
    is_logged_in: !!user,
  }
  fireSnowplowEvent(
    APPLICATION_SUBMISSION_EVENT,
    {
      application_flow_type: 'single_complete_website_submission',
      application_uid: application.id,
      cv_type: 'uploaded_cv',
    },
    [
      {
        key: 'page_context',
        options: {
          link_out_source: contextData.link_out_source,
          pageName: contextData.page_type,
        },
      },
      {
        key: 'job_suggestion_context',
        options: {
          suggested_job_new_flag: contextData.suggested_job_new_flag,
          suggested_job_position: contextData.suggested_job_position,
          suggested_job_uid: contextData.suggested_job_uid,
        },
      },
      {
        key: 'apply_context',
        options: {
          apply_click_type: contextData.apply_click_type,
        },
      },
      {
        key: 'application_flow_version_context',
        options: {
          screeing_question_id: contextData.screeing_question_id,
          screening_question_is_mandatory: contextData.screening_question_is_mandatory,
          screening_question_type: contextData.screening_question_type,
          step_number: contextData.step_number,
          total_number_of_steps: contextData.total_number_of_steps,
        },
      },
    ]
  )
}

export const setGoogleOneTapOpenStatus = utils.create(
  actions.GOOGLE_ONE_TAP_OPEN_STATUS,
  'googleOneTapOpenStatus'
)

export const toggleGoogleOneTapFollowUp = utils.create(
  actions.TOGGLE_GOOGLE_ONE_TAP_FOLLOW_UP
)

export const toggleApplicationSubmittedFollowUp = utils.create(
  actions.TOGGLE_APPLICATION_SUBMITTED_FOLLOW_UP
)

export const markOnPageNPSEventAsTriggered = utils.create(
  actions.MARK_ONPAGE_NPS_EVENT_AS_TRIGGERED
)

export const incrementNumberOfHomePageView = utils.create(
  actions.INCREMENT_NUMBER_OF_HOME_PAGE_VIEW
)

export const incrementNumberOfJobSearchPageView = utils.create(
  actions.INCREMENT_NUMBER_OF_JOB_SEARCH_PAGE_VIEW
)

// FIXME: what is this?
export const incrementNumberOfInboxPageView = utils.create(
  actions.INCREMENT_NUMBER_OF_INBOX_PAGE_VIEW
)

export const initializeTriggerOfCESEventForCAF = utils.create(
  actions.INITIALIZE_TRIGGER_OF_CES_EVENT_FOR_CAF
)

export const markCESEventForCAFAsTriggered = utils.create(
  actions.MARK_CES_EVENT_FOR_CAF_AS_TRIGGERED
)

export const setOptInModalVisibility = utils.create(
  actions.SET_OPT_IN_MODAL_VISIBILITY,
  'options'
)

export const setJobAlertModalState = utils.create(
  actions.SET_JOB_ALERT_MODAL_STATE,
  'options'
)

export const getApplicationDetails = utils.createPromise(
  actions.GET_APPLICATION_DETAILS,
  (applicationId) => (config) => API.applications.v2.getApplicationDetails(applicationId, config)
)

export const updateJobseekerStatus = utils.createPromise(
  actions.UPDATE_JOBSEEKER_STATUS,
  (applicationId: string, status: string) => (config) => {
    if (status === GENERIC_STATUSES.FEEDBACK) {
      fireSnowplowEvent(APPLICATION_PENDING_STATUS_TRIGGERED_EVENT, {})
    }
    return API.applications.v2.updateJobseekerApplication(
      applicationId,
      {
        jobseeker_status: status,
      },
      config
    )
  }
)

export const setApplicationFeedbackState = utils.create(
  actions.JOB_APPLICATION_UPDATE_FEEDBACK_STATE,
  'payload'
)

export const requestJobApplicationFeedback = utils.createPromise(
  actions.JOB_APPLICATION_REQUEST_FEEDBACK,
  (applicationId) => async (config, dispatch) => {
    const updatedApplication = await API.applications.v2.requestJobApplicationFeedback(applicationId, config)

    const MODAL_KEY = getFeedbackModalActionType(applicationId)

    dispatch(setApplicationFeedbackState({
      id: applicationId,
      can_request_feedback: updatedApplication.can_request_feedback,
      have_requested_feedback: updatedApplication.have_requested_feedback,
    }))
    dispatch(showModal(MODAL_KEY))
  }
)

export const clearUserDeleted = utils.create(
  actions.CLEAR_USER_DELETED
)

export const createUnregisteredJobseeker = utils.createPromise(actions.CREATE_UNREGISTERED_JOBSEEKER, (params) => (config) =>
  API.users.createUnregisteredJobseeker(params)
)

export const userIsPendingAnUpdate = utils.create(actions.USER_IS_PENDING_AN_UPDATE)
export const userIsNotPendingAnUpdate = utils.create(actions.USER_IS_NO_LONGER_PENDING_AN_UPDATE)

if (typeof window !== 'undefined' && window.Cypress) {
  window.__googleAuth = {
    setGoogleOneTapOpenStatus,
    toggleGoogleOneTapFollowUp,
  }
}

export { hideJobAlertModal, showJobAlertModal }

// @flow

import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { injectIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import {
  pollCvConversion, pollingCvRetriesExhausted,
  removeCv as removeCvAction,
  uploadCv as uploadCvAction,
} from '../../../actions/actionCreators'
import { catchFileError } from './catchFileErrors'
import UploadDocumentButton from './UploadDocumentButton'
import UploadedDocuments from './UploadedDocuments'
import type { ApplicationState } from '../../../types/applicationState'
import { HBox } from 'talent-ui'
import NewCafDocumentUploadError from '../../conversationalApplicationFlow/steps/DocumentUploadStep/DocumentUploadError.tsx'
import FileDropZone from './FileDropZone.tsx'
import { useWindowDrag } from '../hooks/useWindowDrag.ts'
import { fireSnowplowStructuredEvent } from '../../../tracking/external/trackingClient'

const _UploadCvSection = (ownProps) => {
  const props = useSelector((state: ApplicationState) => {
    return {
      ...ownProps,
      cv: state.documents.applicationCv,
      disableWaitForDocumentConversion: state.documents.disableWaitForDocumentConversion || ownProps.disableWaitForDocumentConversion,
      pathname: state.routing.location.pathname,
    }
  })

  const [isDragging, setIsDragging] = useState(false)

  const dispatch = useDispatch()
  const formData = useFormContext()

  const timeoutId = useRef(null)
  const shouldParseFile = !window.Cypress
  const isApplication = !props.pathname.includes('profile')

  const timeout = (ms) => {
    return new Promise((resolve) => {
      timeoutId.current = setTimeout(resolve, ms)
    })
  }

  const isCvConverted = useRef(false)
  const cvErrors = useRef([])
  const areRetriesExhausted = useRef(false)

  useEffect(() => {
    if (!props.disableWaitForDocumentConversion) {
      isCvConverted.current = props.cv.is_converted
      cvErrors.current = props.cv.errors

      if (props.cv.file?.id && !props.cv.is_converted && !props.cv.converting && !areRetriesExhausted.current) {
        pollDocumentConversion().then()
      }
    }
  }, [props.cv])

  useWindowDrag(
    {
      dragEnterCallback: () => setIsDragging(true),
      dragLeaveWindowCallback: () => setIsDragging(false),
      dragDropWindowCallback: () => setIsDragging(false),
    }
  )

  const uploadCv = async (currentCv, file) => {
    try {
      props.beforeUpload?.(file)
      await dispatch(uploadCvAction(currentCv, file, { shouldParseFile, isApplication }))
      areRetriesExhausted.current = false
      props.onUploadSuccess?.()
    } catch (error) {
      catchFileError(error)
    }
  }

  const removeCv = async (currentCv) => {
    clearTimeout(timeoutId.current)
    await dispatch(removeCvAction(currentCv))
    props.onCvRemove && props.onCvRemove()
  }

  const pollDocumentConversion = async () => {
    const MAX_POLLING_RETRIES = 25
    let retryCount = 0

    while (retryCount < MAX_POLLING_RETRIES) {
      if (isCvConverted.current) {
        break
      }
      try {
        await dispatch(pollCvConversion(props.cv.file.id))
      } catch (error) {
        catchFileError(error)

        areRetriesExhausted.current = true
        return
      }

      await timeout(5000)
      retryCount += 1
    }

    if (retryCount >= MAX_POLLING_RETRIES && !isCvConverted.current) {
      areRetriesExhausted.current = true
      dispatch(pollingCvRetriesExhausted({ errors: [{ key: 'could_not_process_file' }] }))
    }
    if (cvErrors.current.length && !isCvConverted.current) {
      dispatch(pollingCvRetriesExhausted({ errors: [{ key: 'could_not_process_file' }] }))
    }

    timeoutId.current = null
  }

  const handleDragOver = (event) => {
    event.preventDefault()
  }

  const handleDrop = async (event) => {
    event.preventDefault()

    const droppedFiles = Array.from(event.dataTransfer.files)

    fireSnowplowStructuredEvent({
      category: 'tp_page_action',
      action: 'cv_drag_and_drop',
      label: droppedFiles[0]?.name,
    })

    await removeCv(props.cv)

    await uploadCv(props.cv, droppedFiles[0])
  }

  return (
    <HBox
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {
        isDragging
          ? (
            <FileDropZone
              dataTestId='file-dropzone-cv'
              lokaliseKey='job_application_cv_upload_drag_and_drop'
            />
            )
          : <UploadedDocuments
              removeCv={() => () => removeCv(props.cv)}
              documents={[props.cv]}
              errors={props.cv.errors}
            />
      }
      {!props.cv.uploaded && !isDragging
        ? (
          <HBox>
            <UploadDocumentButton
              id='cv'
              primary={props.primary}
              uploadCv={(files) => uploadCv(props.cv, files[0])}
              buttonText='job_application_conversation_flow_document_upload_add_file'
            />
          </HBox>
          )
        : null}
      {
        !isDragging && (
          <NewCafDocumentUploadError
            cv={props.cv}
            formData={formData}
          />)
      }
    </HBox>
  )
}

const UploadCvSection = injectIntl(_UploadCvSection)

export default UploadCvSection

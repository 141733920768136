// @flow

import config from '../config'

import { DataLayer, GTMScript } from 'data-layer'

export const dataLayerDummy = DataLayer

export const googleTagManager = GTMScript(config.google.tagManagerKey)

export const googleMaps = (language: string) => {
  return `https://maps.googleapis.com/maps/api/js?key=${
    config.google.mapsApiKey
  }&libraries=places&language=${language || config.locale.default}`
}
